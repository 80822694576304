// Major components.
@import "reset";
@import "variables";


/* -------------------------------------------------------------------------------------------------------------------
                                                  Header elements
------------------------------------------------------------------------------------------------------------------- */


#content .site-content {
	opacity: 0;
}


.slider-area {
	position: relative;
	overflow: hidden;
	background-color: #1a1919;
	img {
		width: 100%;
	}
	.caption-wrap {
		position: absolute;
		left: 50%;
		top: 50%;
		max-width: 980px;
		text-align: center;
		transform: translate(-50%,-50%);

		h2 {
			text-align: center;
			@include font(70px,1,#fff,700);
			font-family: $third_font;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			text-transform: uppercase;
			white-space: nowrap;
			padding-bottom: 15px;
			margin-bottom: 0;
			@include break($large) {
				font-size: 52px;
			}
			@include break($neutral) {
				font-size: 36px;
			}
			@include break($mobile) {
				font-size: 30px;
			}
			@include break($x-small) {
				font-size: 18px;
			}

		}
		p {
			text-align: center;
			@include font(24px,1,#fff,400);
			font-family: $Playfair;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
			font-style: italic;
			@include break($neutral) {
				font-size: 20px;
			}
			@include break($x-small) {
				font-size: 14px;
			}
			em {
				position: relative;
				&::before {
					content: '';
					display: block;
					width: 5px ;
					height: 5px;
					position: absolute;
					left: -35px;
					top: 26px;
				}				
			}
			.btn-custom {
				margin-top: 40px;
				padding-left: 40px;
				padding-right: 40px;
				background: #ffd539;
				color: #322924;
				text-shadow: none;
				@include break($neutral) {
					margin-top: 15px;					
				}

				&:hover {
					background: #322924;
					color: #ffd539;
				}
			}
		}
	}
	.flex-control-nav {
		position: absolute;
		width: 100%;
		z-index: 100;
		bottom:40px;
		text-align: center;
		@include break($mobile) {
			display: none
		}
		li {
			display: inline-block;
			padding: 0 10px;
		}
		a {
			text-decoration: none;
			width: 14px;
			height: 14px;
			font-size: 0;
			border-radius: 50%;
			display: block;
			border: 1px #eadcc7 solid;
			position: relative;
			&.flex-active {
				&:after {
					background: #f0e0c1;
					content: '';
					display: block;
					width: 8px ;
					height: 8px;
					position: absolute;
					left: 2px;
					top: 2px;					
					border-radius: 50%;
				}
			}
		}

	}
}

.site-header {
	position: relative;
	.header-wrap {
		width: 100%;
		background: #9c7b49;
		@include break($mobile) {
			min-height: 0;
			padding-top: 60px;
		}
	}
	p {
		padding: 0;
	}

	.row-top {
		overflow: hidden;
		padding: 20px 50px;
		@include break($neutral) {
			padding: 15px 20px;
		}
		@include break($mobile) {
			display: none;
		}

		.get-free-quote {
			float: right;
			padding-top:5px;
			a.btn-custom {
				&:hover {
					background: #322924;
					color: #ffd539;
				}
			}
		}
		.phone {
			float: right;
			padding-right: 40px;
			@include break($medium) {
				padding-right: 15px;
			}
			@include break($neutral) {
				padding-right: 25px;
			}

			span {
				@include font(24px,1,#322924,700);
				font-family: $third_font;
				font-style: italic;
				@include break($medium) {
					font-size: 20px;
				}
			}
			a {
				display: block;
				padding-left: 45px;
				@include font(30px,1,#322924,700);
				font-family: $main_font;
				text-decoration: none;
				letter-spacing: 2px;
				@include break($medium) {
					font-size: 24px;
					letter-spacing: 1px;
				}
			}
		}
		.site-logo {
			left: 0;
			z-index: 11;
			float: left;
			padding-left: 10px;
			@include break($neutral) {
				margin-top: -10px;
				padding-left: 0;
			}
			a {
				text-decoration: none;
				img {
					float: left;
					@include break($medium) {
						width: 220px;
					}
				}
				& + img {
					padding-top:25px;
					padding-left: 15px;
					@include break($medium) {
						width: 240px;
						padding-top:18px;
					}
					@include break($neutral) {
						padding-left: 0px;
						display: block;
						padding-top: 0;
						width: 220px;
					}
				}
			}
		}

	}

	// Fixed scrolling row on mobile.
	.row-mobile {
		position: fixed;
		height: 60px;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;

		.icons-mobile {
			text-align: right;
			padding: 0 15px 0 0;
			a {
				margin-left: 10px;
				color: #fff;
				font-size: 26px;
			}
		}
		.site-logo {
			width: 150px;
			position: absolute;
			top: 5px;
			left: 50%;
			transform: translateX(-50%);			
			a + img {
				display: block;
				margin-top: -6px;
				width: 120px;
				margin-left: auto;
				margin-right: auto;
			}
		}

	}
	.shopping-container {
		display: inline-block;
		cursor: pointer;
		vertical-align: top;
		.cart-popup {
			height: 0;
		}
	}
}

/* Primary menu.
------------------------------------------------------------ */
.row-menu {
	position: relative;
	background:#1a1919;
	box-shadow: 0 2px 6px 0 ;
	clear:both;
	min-height: 50px;
	@include break($medium) {
		min-height: auto;
	}

	&.smaller {
		.header-menu {
			position: fixed;
			top: 0;
			width: 100%;
			left:0;					
			z-index: 1000;			
		}
	}

	&.sticky {
		position: fixed;
		top: -100px;
		left: 0;
		width: 100%;
		transition: .3s;
		opacity: .5;
		z-index: 11;
		border-bottom: 1px solid #ddd;
		box-shadow: 0 4px 0 rgba(0, 0, 0, .2);
		// Animate menu on vertical scroll movements.
		.sticky & {
			top: 0;
			opacity: 1;
		}
		// When user is logged in.
		.admin-bar .sticky & {
			top: 32px;
		}
		.site-logo {
			width: 230px;
		}
		.header-menu .main-menu > li {}
	}

}

.header-menu {
	text-align: center;

	// Common styles.
	.main-menu {
		display: inline-block;
		@include break($large) {		}
		@include break($medium) {		}
		@include break($neutral) {		}
		li {
			display: block;
			z-index: 2;
			position: relative;
			a {
				text-decoration: none;
				position: relative;
				display: block;
				font-family: $main_font;
			}
			span {
				position: relative;
			}
		}
		// 1st level menus.
		> li {
			display: inline-block;
			margin-right: 20px;
			> a {
				@include font(16px, 1.2, #fae5c3, 700);
				margin:  0 20px;
				padding: 14px 0;
				@include transition();
				text-transform: uppercase;
				letter-spacing: 1px;
				border-bottom: 3px transparent solid;
				@include break($large) {
					margin: 0 16px;
				}
				@include break($medium) {
					font-size: 15px;
					margin: 0 6px;
				}
				@include break($neutral) {
					font-size: 14px;
					margin: 0 ;
					padding: 10px 0;
					letter-spacing: 0.5px;
				}


				// Hover root item.
				&:focus,
				&:hover {
					color: #000;
					border-bottom-color: #9c7b49;
				}
			}

			// Parent root menu item.
			&.menu-item-has-children {			}

			// Active root menu item.
			&.current_page_item,
			&.current-menu-item,
			&.current-page-ancestor,
			&.current-menu-ancestor {
				> a {
					color: #fff9ef;
				}
			}

			// Animate dropdown menu.
			&.sfHover,
			&:hover {
				> a {
					color: #fff9ef;
					border-bottom-color: #9c7b49;
				}
				> ul.sub-menu {
					transform: translateY(0);
					opacity: 1;
					visibility: visible;
				}
			}
			& + li a::before {
				content: '';
				display: block;
				background: #9c7b49;
				width: 5px ;
				height: 5px;
				border-radius: 50%;
				position: absolute;
				left: -35px;
				top: 22px;
				@include break($medium) {
					left: -25px;
				}
				@include break($neutral) {
					display: none;
				}

			}

			// 2nd Level.
			> ul.sub-menu {
				background: #10adba;
				border-top: none;
				padding: 15px 15px;
				display: none;
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 2;
				width: 290px;
				opacity: 0;
				visibility: hidden;
				transition: all .4s ease-out;
				transform: translateY(30px);
				margin-top: 5px;
				border-radius: 21px;
				> li {
					// Current sub menu item.
					&.current_page_item > a,
					&.current-menu-ancestor > a,
					&.current-menu-item > a {
						color: #fff;
					}
					> a {
						font-size: 15px;
						color: #e9e9e9;
						text-align: left;
						padding-top: 4px;
						padding-bottom: 4px;
						@include break($neutral) {
							font-size: 14px;
						}
						&::after {
							content: '';
							display: inline-block;
							font-family: 'FontAwesome';
							float: right;
							opacity: 0;
							margin-right: 15px;
							transition: all .4s ease-out;
							margin-top: 1px;
						}

						&.sf-with-ul {
						}
						// Hover sub menu item.
						&:hover {
							color: #fff;
							&::after {
								opacity: 1;
								margin-right: 10px;
							}
						}
					}
					// 3nd Level.
					ul.sub-menu {
					}
					// Animate dropdown menu.
					&.sfHover,
					&:hover {
						> ul.sub-menu {
							transform: translateY(0);
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}

}

/* Mobile menu toggle icon.
------------------------------------------------------------ */
.mobile-menu-icon {
	display: none;
	height: 22px;
	width: 22px;
	z-index: 10;
	color: #4e331c;
	text-align: center;
	text-decoration: none;
	position: absolute;
	left: 22px;
	top: 12px;
	&:hover,
	&:focus,
	&:active {
		color: #000;
	}
	&::after {
		content: '\e601';
		font-family: $simple_line_icons;
		font-size: 24px;

	}
	@include break($mobile) {
		display: block;
	}
	i {
		margin: 0;
	}
	.fa-bars {
		display: block;
	}
	.fa-times {
		display: none;
	}
	.mm-opened & {
		.fa-bars {
			display: none;
		}
		.fa-times {
			display: block;
		}
	}
}



.mobile-phone-btn {
	width: 44px;
	height: 44px;
	background: #462a17;
	border-radius: 50%;
	position: absolute;
	top: 8px;
	right: 20px;
	text-align: center;
	line-height: 46px;
	font-size: 22px;
	overflow: hidden;
	i {
		color: #ffd539;
	}
}

/* Page title.
------------------------------------------------------------ */
.page-title-row {
	width: 100%;
	left: 0;
	background:transparent;
	background-size: cover;
	padding: 80px 0 70px;
	transition: .2s ease-in-out all;

	@include break($large) {
		background-position: 50% 0;
		padding: 60px 0 50px;
	}
	@include break($medium) {
		padding: 50px 0 40px;
	}
	@include break($neutral) {
		padding: 30px 0 30px;
	}

	.page-title {
		z-index: 1;
		.single-stores &,
		.page-template-page-locations & {
			display: none;
		}
		div.title {
			z-index: 2;
			opacity: 1;
			@include font(30px, 1.2, #fff, 700);
			@include break($medium) {
				font-size: 24px;
				padding-left: 20px;
			}
			@include break($neutral) {
				
			}
			@include break($mobile) {
				font-size: 30px;
			}
			@include break($x-small) {
			}

			.loaded & {
				transition: all 0.3s ease-out;
				opacity: 1;
				transform: scale(1);
			}
			h1 {
				@include font(70px,1,#f8f2e9,700);
				font-family: $third_font;
				text-align: center;
				position: relative;
				text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
				border: none;
				position: relative;
				margin-bottom: 0;
				padding: 0;
				text-transform: uppercase;
				@include break($large) {
					font-size: 60px;				
				}
				@include break($medium) {
					font-size: 48px;				
				}
				@include break($neutral) {
					font-size: 42px;				
				}
				@include break($mobile) {
					font-size: 30px;
				}
				@include break($x-small) {
					font-size: 24px;
				}


				&:after {
					content: '';
					display: block;
					width: 225px;
					height: 15px;
					object-fit: contain;
					background-position: center top;
					background-repeat: no-repeat;
					margin: 10px auto 0;
				}
			}
		}
	}
	&.no-image {
		.page-title {
			//width: 100%;
			&::after {
				//display: none;
			}
		}
	}
}
/* FlexSlider core styles.
------------------------------------------------------------ */
.flexslider {
	position: relative;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	@include break($x-small) {
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	.slides {
		li {
			display: none;
		}
		li > img {
			width: 100%;
			display: block;
			-moz-user-select: none;
			height: auto;
			@include break($mobile) {			}
			@include break($x-small) {
				min-width: 480px;
				position: relative;
				left: 50%;
				transform: translateX(-50%);							
			}
		}
	}

	.flex-pauseplay span {
		text-transform: capitalize;
	}

	/* Clearfix for the .slides element */
	.slides:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	html[xmlns] .slides {
		display: block;
	}

	* html .slides {
		height: 1%;
	}
	/* No JavaScript Fallback */
	/* If you are not using another script, such as Modernizr, make sure you
	 * include js that eliminates this class on page load */
	.no-js .slides > li:first-child {
		display: block;
	}
}


/* Buttons & Links
------------------------------------------------------------ */
// Default button.
.order-online a,
.btn {
	text-decoration: none;
	border-radius: 20px;
	@include font(16px, 1.2, #9c7b49, 400);
	font-family: $open_sans;
	padding: 8px 25px ;
	display: inline-block;
	position: relative;
	border: 1px #9c7b49 solid;
	@include transition();
	&:focus,
	&:hover {
		box-shadow: none;
		color: #fff;
		background: #9c7b49;
	}
	&:active {
		top: 2px;
	}
}

// Custom button.
.btn-custom {
	text-decoration: none;
	border-radius: 30px;
	box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.24);
	@include font(16px, 1.2, #322924, 700);
	font-family: $main_font;
	text-transform: uppercase;
	padding: 15px 40px 15px;
	display: inline-block;
	outline: none;
	border: none;
	@include transition();
	background: #ffd539;
	position: relative;
	font-style: normal;
	@include break($neutral) {
		font-size: 14px;
		padding: 10px 20px;
	}
	&:hover {
		box-shadow: none;
		background: #000;
		color:#e6c786;
	}
	&:focus,
	&:active {
		top: 2px;
	}

}