// ++++++++++++++++++++++++ Variables ++++++++++++++++++++++++

// Fonts.
//$baskerville:'Baskerville', serif;
//$libre_baskerville: 'Libre Baskerville', serif;
$lato:'Lato', sans-serif;
$Playfair:'Playfair Display', sans-serif;
//$Saira: 'Saira Condensed', sans-serif;
//$Teko: 'Teko', sans-serif;;
//$poppins: 'Poppins', sans-serif;
$open_sans: 'Open Sans', sans-serif;
$cormorant: 'Cormorant Garamond', sans-serif;
//$work_sans: 'Work Sans', sans-serif;
//$dancing_script: 'Dancing Script', cursive;
//$pt_sans: 'PT Sans', sans-serif;
//$pt_serif: 'PT Serif', serif;
//$yanone: 'Yanone Kaffeesatz', sans-serif;
//$crimson: 'Crimson Text', serif;
//$default_family: 'Helvetica','Arial', sans-serif;

$main_font: $lato;
$second_font: $open_sans;
$third_font: $cormorant;

$font_awesome: FontAwesome;
$ionicons: 'Ionicons';
$simple_line_icons: 'simple-line-icons';
$font_woocommerce: 'WooCommerce';
$mat_icons: 'Material Icons';


// default 1rem=16px
$xsmall-font-size: 0.625rem; //10px
$small-font-size: 0.75rem; //12px
$medium-font-size: 0.875rem; //14px
$base-font-size: 100%; //16px
$main-font-size: 1.13rem; //18px
$x-font-size: 1.25rem; //20px
$xl-font-size: 1.45rem; //24px
$xl-up-font-size: 1.875rem; //30px
$xxl-font-size: 2.0rem; //~32px
$xxxl-font-size: 2.25rem; //~36px
$large-font-size: 2.5rem; //~40px


// Breakpoints variables (required by break @mixin).
$Xlarge: x-large;
$large: large;
$desktop: desktop;
$medium: medium;
$neutral: neutral;
$mobile: mobile;
$not-mobile: not-mobile;
$x-small: x-small;
$xs-small: xs-small;


// Colors.
$beige: #d9a492;
$red: #ed0f2a;
$red_light: #ff3333;
$reb_dark: #7e394e;
$pink: #d9a492;
$pink_light: #ffcfbc;
$green: #3ab862;
$mint_green: #56cbbf;
$blue: #1a5ca1;
$blue_light: #155ca5;
$blue_dark: #060e5a;
$turquoise:#10adba;
$rouge-three:#841b1d;
$orange: #ef783d;
$yellow: #ffdb4f;
$golden: #ebd396;
$purple: #4e4e4e;
$grey: #3ab862;
$grey_xslight: #f4f4f4;
$grey_light: #f1f1f1;
$grey_dark: #787878;
$grey_black: #424242;
$white_light: #f2f2f2;

$error_bg: #f7d7d7;
$color_primary: #303030;
$black-nine: #2d2d2d;
$color_secondary: #000;
$hover_color: #b72026;

$color_link: #841b1d ;
$color_account_second: #fff;

$available: #6dc3ac;

$font_color_main: $color_secondary;
$font_color_second: $rouge-three;
$font_color_third: $black-nine;


$color_info_window: #5bc992;
$woo_btn_custom_background: $grey_xslight;
$woo_btn_custom_color: #000;
$light_color: #fff;

// ++++++++++++++++++++++++ Mixins ++++++++++++++++++++++++ */
// Consistent with bootstrap breakpoints.
@mixin break($point) {
	@if $point == 1600 {
		@media (max-width: 1600px) {
			@content;
		}
	}
	@if $point == x-large {
		@media (max-width: 1729px) {
			@content;
		}
	} @else if $point == large {
		@media (max-width: 1329px) {
			@content;
		}
	} @else if $point == desktop {
		@media (min-width: 1330px) {
			@content;
		}
	} @else if $point == medium {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point == neutral {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == not-mobile {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point == x-small {
		@media (max-width: 479px) {
			@content;
		}
	} @else if $point == xs-small {
		@media (max-width: 360px) {
			@content;
		}
	}
}

// Adds circles via styles(normally on ::before/::after).
@mixin circle($size: 10px, $color: #fff) {
	width: $size;
	height: $size;
	background: $color;
	border-radius: 50%;
	content: '';
	display: inline-block;
}

// Clear floating.
@mixin clearfix() {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin font($font_size:14px, $line_height:1.5, $color:$color_primary, $font_weight: 400) {
	font-size: $font_size;
	line-height: $line_height;
	color: $color;
	font-weight: $font_weight;
}

// Link hover effect 1(text overlay).
@mixin hover_text_overlay($color:darken($blue, 10%)) {
	position: relative;
	display: inline-block;
	&::before {
		color: $color;
		content: attr(data-hover);
		position: absolute;
		opacity: 0;
		transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
		transition: transform 0.3s, opacity 0.3s;
		pointer-events: none;
	}
	&:hover {
		outline: none;
		&::before {
			transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
			opacity: 1;
		}
	}
}

// Link hover effect 2(underline).
@mixin hover_underline($color:$blue) {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 1px;
		background: $color;
		opacity: 0;
		transition: opacity 0.3s, transform 0.3s;
		transform: translateY(-10px);
	}
	&:hover,
	&:focus {
		outline: none;
		&::before {
			opacity: 1;
			transform: translateY(-5px);
		}
	}
}

// ++++++++++++++++++++++++ Animations ++++++++++++++++++++++++ */
//
@keyframes fadeOutText {
	0% {
		color: transparent;
	}
	80% {
		color: transparent;
	}
	100% {
		color: #fff;
	}
}

/* Animations */
// Spin animation
.fa-spin {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear
}

@keyframes fa-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@keyframes fillWidth {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes shake {
	0%, 100% {
		transform: translate3d(-10px, 0, 0)
	}
	50% {
		transform: translate3d(10px, 0, 0)
	}
}
@mixin inherit() {
	font-size: inherit;
	font-style: inherit;
	font-family:inherit;
	line-height:inherit;
	text-transform: inherit;
	color:inherit;
	font-weight:inherit;
	padding: 0;
	margin: 0;
	text-align: inherit;
	&:before, &:after{
		display: none;
	}
}
@mixin iconbefore( $glyph: "\e001" ) {
	font-family: $font_woocommerce;
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin-right: .618em;
	content: $glyph;
	text-decoration: none;
}

@mixin iconafter( $glyph: "\e001" ) {
	font-family: $font_woocommerce;
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	margin-left: .618em;
	content: $glyph;
	text-decoration: none;
}
@mixin no-before-after() {
	&:before, &:after{
		display: none;
	}
}
@mixin transition($time:.2) {
	transition: $time+s ease-in-out;
}

